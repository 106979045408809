//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { PageWithLinksTypes } from './z_page-types'

//Styles
import styles from './css-pages/pages.module.scss'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import PageWithLinks from '../components/templates/pageWithLinks'
import PageBackground from '../components/free/pageBackground'
import ProfileCard from '../components/free/profileCard'

const Ombudsman: FunctionComponent<PageWithLinksTypes> = ({ data }) => {
  return (
    <FontSizeProvider>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet="utf-8" />
        <title>OOA - Ombudsman</title>
        <link rel="canonical" href="https://ooa.csi.edu/ombudsman/" />
        <meta
          name="description"
          content="The CSI Office on Aging Ombudsman Program is the eyes, ears and voice in promoting quality of care and life for residents of nursing homes and/or assisted living facilities."
        />
      </Helmet>

      <Header />

      <Layout>
        <PageWithLinks
          html={data.markdownRemark.html}
          frontmatter={data.markdownRemark.frontmatter}
        >
        </PageWithLinks>
      </Layout>

      <Footer />
      <PageBackground />
    </FontSizeProvider>
  )
}

export default Ombudsman

export const ombudsmanQuery = graphql`
  query ombudsman {
    markdownRemark(frontmatter: { template_key: { eq: "ombudsman-page" } }) {
      html
      frontmatter {
        title
        links {
          link
          link_name
        }
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        team_pictures {
          name
          title
          img {
            childImageSharp {
              fluid(maxWidth: 1700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
